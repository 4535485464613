import { Link } from "react-router-dom";
import { Carousel, Image } from "react-bootstrap";
import Loader from "./Loader";
import Message from "./Message";
import { useGetProductsQuery } from "../slices/productsApiSlice";
import VinylBanner from "./image.jpg";

const ProductCarousel = () => {
  const { data: products, isLoading, error } = useGetProductsQuery();

  return isLoading ? (
    <Loader />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    <Carousel pause="hover" className="bg-primary mb-4">
      {products.map((product) => (
        <Carousel.Item key={product._id}>
          {/* <Link to={`/product/${product._id}`}> */}
          <Image src={VinylBanner} alt={product.name} fluid />
          <Carousel.Caption className="carousel-caption">
            <h2>{product.name}</h2>
            <p>{product.description}</p>
          </Carousel.Caption>
          {/* </Link> */}
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default ProductCarousel;
